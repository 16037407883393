import Seo from "../../components/_App/seo";
import Navbar from "../../components/_App/Navbar";
import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import * as convertStyled from "../../assets/css/convert-case.module.css";
import CounterText from "../../helpers/CounterText";
import DownloadAndCopyButtons from "../../helpers/buttons";
import { generateSlugifyURL } from "../../helpers/covert-case"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"

const parent = [
  {
    name: "Tools",
    link: "tools"
  }
];

function SlugifyURL() {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [previewValue, setPreviewValue] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [message, setMessage] = useState("");

  function handleTextField(e) {
    setTextFieldValue(e.target.value);
    setPreviewValue(generateSlugifyURL(e.target.value));
  }

  useEffect(() => {
    setIsInputEmpty(textFieldValue === "");
  }, [textFieldValue])

  function handleText(e) {
    const result = e.target.value.replace(/[^a-z0-9 ]/gi, "");
    setTextFieldValue(result);
  }
  const showMessage = (msg) => {
    setMessage(msg);
  };
  return (
    <Layout>
      <Seo title="Generate Slugify URLs Tool" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Slugify URL Generator</h1>
            <div className="bar"></div>
          </div>
          <Breadcrumbs pageTitle="Slugify URL Generator" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <textarea
                  name="" value={textFieldValue} onChange={(e) => handleTextField(e)} className={convertStyled.text_field} id="" cols="30" rows="10"
                />
                <CounterText text={textFieldValue} /> {/*This is word 1 characters code-------------------------->*/}
              </div>
              <div className="col-lg-6">
                <textarea
                  name="" value={previewValue} onChange={(e) => handleText(e)} className={`${convertStyled.text_field}`} id="" cols="30" rows="10"
                />
                <DownloadAndCopyButtons previewValue={previewValue}  disabled={isInputEmpty} showMessage={showMessage} />  {/*This is Button's code-------------------------->*/}
                {message && (<CopiedMessage message={message} />)} {/* Display the message */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  );
}
export default SlugifyURL;
